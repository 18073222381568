import * as React from "react";
import { LoginOutlined, MenuOutlined, SendOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../../core/models/constents/core.constants";
import SiteLogo from "../../../core/components/SiteLogo";
import { Button } from "antd";
import { t } from "i18next";

const NavigationHeader: React.FC<any> = () => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const handleLoginClick = () => {
    navigate(ROUTES_CONST.AUTH + ROUTES_CONST.LOGIN);
  };
  const handleBookDemoClick = () => {
    navigate(ROUTES_CONST.ROOT + ROUTES_CONST.BOOKDEMO);
  };
  return (
    <div className="flex bg-white justify-between drop-shadow font-poppins ">
      <nav className=" w-full my-0 mx-auto">
        <div className="mx-auto w-full">
          <div className="flex mx-auto justify-between w-full py-4">
            <div className="flex items-center w-full">
              <div className="w-full text-[#838383] ">
                <div className="flex justify-start w-full ">
                  <div className="pb-2 pl-4 pr-8 ">
                    <SiteLogo showSubtitle={false} />
                  </div>
                  <div className="hidden sm:flex w-full justify-between">
                    <div className="flex ">
                      <Link
                        to={ROUTES_CONST.ROOT}
                        className="py-0 px-4 content-center cursor-pointer border-solid border-b-2 border-transparent hover:text-sky-400"
                      >
                        {t("menu.home")}
                      </Link>
                      <Link
                        to={ROUTES_CONST.ROOT + ROUTES_CONST.NEWS}
                        className="py-0 px-4 content-center cursor-pointer border-solid border-b-2 border-transparent hover:text-sky-400"
                      >
                        {t("menu.news")}
                      </Link>
                      <Link
                        to={ROUTES_CONST.ROOT + ROUTES_CONST.ABOUTUS}
                        className="py-0 px-4 content-center cursor-pointer border-solid border-b-2 border-transparent hover:text-sky-400"
                      >
                        {t("menu.about_us")}
                      </Link>
                      <Link
                        to={ROUTES_CONST.ROOT + ROUTES_CONST.SERVICES}
                        className="py-0 px-4 content-center cursor-pointer border-solid border-b-2 border-transparent hover:text-sky-400"
                      >
                        {t("menu.services")}
                      </Link>
                      <Link
                        to={ROUTES_CONST.ROOT + ROUTES_CONST.BOOKDEMO}
                        className="py-0 px-4 content-center cursor-pointer border-solid border-b-2 border-transparent hover:text-sky-400"
                      >
                        {t("menu.contact")}
                      </Link>
                    </div>
                    <div className="hidden lg:flex ">
                      <div className="py-0 px-2 content-center ">
                        <Button
                          size="large"
                          className="reltive rounded-md px-8 pb-3 pt-2 hover:!bg-dx-light hover:!text-white !h-12 border border-black"
                          onClick={handleLoginClick}
                          icon={<LoginOutlined className="text-[13px]" />}
                        >
                          {t("menu.log_in")}
                        </Button>
                      </div>
                      <div className="py-0 px-2 content-center ">
                        <Button
                          className="reltive rounded-md px-6 pb-3 pt-2 hover:!bg-dx-light hover:!text-white !h-12"
                          type="primary"
                          size="large"
                          onClick={handleBookDemoClick}
                          icon={<SendOutlined className="text-[13px]" />}
                        >
                          <span className="align-text-top">
                            {t("menu.book_a_demo")}
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex lg:hidden items-center">
                <button onClick={() => setToggleMenu(!toggleMenu)}>
                  <MenuOutlined className="text-lg h-12 mr-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`fixed z-40 w-[100vw] right-0 bg-white text-gray-500 overflow-hidden flex flex-col lg:hidden gap-12  origin-top duration-700 ${
            !toggleMenu ? "h-0" : "h-auto"
          }`}
        >
          <div className="px-8">
            <div className="flex flex-col font-semibold tracking-wider">
              <div className="block sm:hidden">
                <Link
                  to={ROUTES_CONST.ROOT}
                  className="block py-1 px-3 hover:underline border-0"
                >
                  {t("menu.home")}
                </Link>
                <Link
                  to={ROUTES_CONST.ROOT + ROUTES_CONST.NEWS}
                  className="block py-1 px-3 hover:underline border-0"
                >
                  {t("menu.news")}
                </Link>
                <Link
                  to={ROUTES_CONST.ROOT + ROUTES_CONST.ABOUTUS}
                  className="block py-1 px-3 hover:underline border-0"
                >
                  {t("menu.about_us")}
                </Link>
                <Link
                  to={ROUTES_CONST.ROOT + ROUTES_CONST.SERVICES}
                  className="block py-1 px-3 hover:underline border-0"
                >
                  {t("menu.services")}
                </Link>
                <Link
                  to={ROUTES_CONST.ROOT + ROUTES_CONST.BOOKDEMO}
                  className="block py-1 px-3 hover:underline border-0"
                >
                  {t("menu.contact")}
                </Link>
              </div>
              <div className="block">
                <div
                  className="py-1 px-3 hover:underline border-0"
                  onClick={handleLoginClick}
                >
                  {t("menu.log_in")}
                </div>
                <div className="block py-2">
                  <Link
                    to={ROUTES_CONST.ROOT + ROUTES_CONST.BOOKDEMO}
                    className="block py-1 px-3 hover:underline border-0"
                  >
                    {t("menu.book_a_demo")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavigationHeader;
