import { createSlice } from "@reduxjs/toolkit";

export interface authState {
  auth: {
    user_id: number;
    user_email: string;
    user_token: string;
    user_refreshToken: string;
    user_token_validTo: string;
    user_name: string;
    user_role: string;
    user_pkg: string;
    isLoggedin: boolean;
  };
}
const initialState: authState = {
  auth: {
    user_id: -1,
    user_email: "",
    user_name: "user",
    user_role: "",
    user_pkg: "",
    user_token: "",
    user_refreshToken: "",
    user_token_validTo: "",
    isLoggedin: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      state.auth = { ...state.auth, ...action.payload, isLoggedin: true };
    },
    logout: (state) => {
      state.auth = { ...initialState.auth };
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
