import * as React from "react";
import Loader from "../../../core/components/Loader";
import MainHeader from "../../components/MainHeader";
import Footer from "../../components/Footer";
import { Outlet } from "react-router-dom";
import { FloatButton } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";

type LandingLayoutProps = {
  //
};

const LandingLayout: React.FC<any> = () => {
  return (
    <div className="min-h-screen">
      <div className="min-h-screen h-full flex flex-col overflow-visible place-content-between">
        <MainHeader />
        <React.Suspense
          fallback={<Loader styleLoadingOutlined={{ fontSize: 100 }} />}
        >
          <Outlet />
        </React.Suspense>
        <Footer />
      </div>
      <FloatButton.BackTop
        shape="circle"
        style={{ right: 12 }}
        icon={
          <UpCircleOutlined className="absolute top-0 left-0 p-0 rounded-full text-[40px] bg-sky-200 text-sky-500" />
        }
      />
    </div>
  );
};

export default LandingLayout;
