import * as React from "react";
import Sticky from "react-stickynode";
import NavigationHeader from "../NavigationHeader";

const MainHeader: React.FC<any> = () => {
  return (
    <div className="w-full z-10 overflow-visible">
      <div className="bg-white z-10 sticky top-0 w-full ">
        <Sticky enabled={true} top={0}>
          <NavigationHeader />
        </Sticky>
      </div>
    </div>
  );
};

export default React.memo(MainHeader);
