import { RuleObject } from "antd/es/form";
import { INPUT_ALLOWED_KEYS } from "../models/constents/core.constants";
import { VALIDATIONS } from "../models/constents/validation.constants";
import {
  email_Regex,
  NON_SPECIAL_CHARS,
  PASSWORD,
  simple_mobile_Regex,
  user_name_Regex,
  VALIDID_NUMBER,
  WHITE_SPACE_COUNT_REG,
} from "./regex.helper";

export const IsValidID_Number = (input: string) => input.match(VALIDID_NUMBER);
export const IsValidEmail = (input: string) => input.match(email_Regex);
export const IsValidUserName = (input: string) => input.match(user_name_Regex);
export const IsValidSimpleMobile = (input: string) =>
  input.match(simple_mobile_Regex);
export const IsValidContactNumber = (input: string) =>
  input.match(simple_mobile_Regex);
export const IsValidName = (input: string) =>
  input.trim().length >= VALIDATIONS.NAME_MIN &&
  input.trim().length <= VALIDATIONS.NAME_MAX;
export const IsPositiveInteger = (input: string) =>
  Number.parseInt(input) >= 0 &&
  Number.parseFloat(input) == Number.parseInt(input);
export const validatePercentageInputOnKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  let value = (e.target as HTMLInputElement).value;
  value = value.trim() === "" ? e.key : value + e.key;
  if (
    INPUT_ALLOWED_KEYS.includes(e.key) ||
    (!isNaN(Number.parseFloat(value)) &&
      Number.parseFloat(value) >= 0 &&
      Number.parseFloat(value) <= 100 &&
      value.length < 6)
  ) {
  } else {
    e.preventDefault();
    return false;
  }
};
export const validateMobileInputOnKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  let value = (e.target as HTMLInputElement).value;
  if (value === "") {
    value = e.key;
  }
  if (
    INPUT_ALLOWED_KEYS.includes(e.key) ||
    (!isNaN(Number.parseInt(e.key)) &&
      Number.parseInt(value) >= 0 &&
      Number.parseInt(value) <= 1000000000)
  ) {
  } else {
    e.preventDefault();
    return false;
  }
};
export const validateIdNumberInputOnKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  let value = (e.target as HTMLInputElement).value;
  value = value.trim() === "" ? e.key : value + e.key;
  if (
    INPUT_ALLOWED_KEYS.includes(e.key) ||
    (!isNaN(Number.parseInt(e.key)) &&
      Number.parseInt(value) >= 0 &&
      Number.parseInt(value) <= 10000000000000 &&
      value.length < 14)
  ) {
  } else {
    e.preventDefault();
    return false;
  }
};
export const IsValidAddress = (input: string) => {};
export const leastCharVerified = (input: string, length: number) => {};
export const charCaseVerified = (input: string) => {};
export const OneNumericVerified = (input: string) => {};
export const specialCharVerified = (input: string) => {};
export const IsValidPassword = (input: string) => {
  if (input.match(PASSWORD) && !input.match(NON_SPECIAL_CHARS)) return true;
  return false;
};
export const handleTextAreaKeyDown = (
  event: React.KeyboardEvent<HTMLTextAreaElement>
) => {
  let value = (event.target as HTMLTextAreaElement).value;
  value += !INPUT_ALLOWED_KEYS.includes(event.key) ? event.key : "";
  if ((value.match(WHITE_SPACE_COUNT_REG)?.length ?? 0) > 100) {
    if (!INPUT_ALLOWED_KEYS.includes(event.key)) {
      event.preventDefault();
      return false;
    }
  }
};

export const formNameValidator = (
  _: RuleObject,
  value: any,
  blank: string,
  invalid: string
): Promise<any> =>
  value === ""
    ? Promise.reject(new Error(blank))
    : IsValidName(value)
    ? Promise.resolve()
    : Promise.reject(new Error(invalid));

export const form100WordValidator = (
  _: RuleObject,
  value: any,
  invalid: string
): Promise<any> => {
  let wordCount = (value as string).match(WHITE_SPACE_COUNT_REG);
  return !wordCount || wordCount.length < 101
    ? Promise.resolve()
    : Promise.reject(new Error(invalid));
};
