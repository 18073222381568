import { Form, FormItemProps, InputProps } from "antd";
import Password from "antd/es/input/Password";
import * as React from "react";

type DXFormPasswordProps = {
  itemProps: FormItemProps;
  inputProps: InputProps;
};

const DXFormPassword: React.FC<DXFormPasswordProps> = (
  props: DXFormPasswordProps
) => {
  const { className, colon, ...restItemProps } = props.itemProps;
  return (
    <Form.Item
      className={"ant-form-item-label-show " + className}
      colon={false}
      {...restItemProps}
    >
      <Password {...props.inputProps} />
    </Form.Item>
  );
};

export default React.memo(DXFormPassword);
