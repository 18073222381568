import * as React from "react";

type ResetPasswordPageProps = {
  //
};

const ResetPasswordPage: React.FC<any> = () => {
  return <div>ResetPasswordPage</div>;
};

export default ResetPasswordPage;
