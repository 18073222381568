import * as React from "react";
import SiteLogo from "../../../core/components/SiteLogo";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const SignUpPage: React.FC<any> = () => {
  // const [form] = Form.useForm<AuthInitialState>();
  const { t } = useTranslation();
  const [, /* api */ contextHolder] = notification.useNotification();
  return (
    <div className="w-full min-h-screen content-center text-center">
      {contextHolder}
      <div className="max-w-dx-lg w-full my-0 mx-auto">
        <div className="flex md:fixed justify-center md:top-5 md:left-6">
          <SiteLogo />
        </div>
        <div className="flex justify-between">
          <div className="lg:max-w-[]">
            <div>{t("auth.signup.content.join_community_of_job_seekers")}</div>
            <div>
              {t("auth.signup.content.create_free_account_start_preparing")}
            </div>
            <div>{t("auth.signup.content.welcome_to_desiexpert_pera")}</div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
