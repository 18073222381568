import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth.slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "desiexpert",
  storage,
};

const reducer = {
  auth: persistReducer(persistConfig, authReducer),
};
const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["actionWithFilePayload"],
        ignoredPaths: ["stateWithFile"],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
