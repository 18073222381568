import * as React from "react";

const HomeBanner = React.lazy(() => import("../../components/HomeBanner"));
const HomeServices = React.lazy(() => import("../../components/HomeServices"));
const HomePricing = React.lazy(() => import("../../components/HomePricing"));
const TrainingFeature = React.lazy(
  () => import("../../components/TrainingFeature")
);

const HomePage: React.FC<any> = () => {
  return (
    <>
      <HomeBanner />
      <HomePricing />
      <HomeServices />
      <TrainingFeature />
    </>
  );
};

export default HomePage;
