import { Image } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";

type NewsTileProps = {
  imageSrc: string;
  newsTitle: React.ReactNode;
  newsContent: React.ReactNode;
  newsLink: string;
};

const NewsTile: React.FC<NewsTileProps> = (props: NewsTileProps) => {
  const { imageSrc, newsContent, newsLink, newsTitle } = props;
  return (
    <Link className="inline-block m-4 rounded-md p-0" to={newsLink}>
      <div className="w-[300px]">
        <div>
          <Image src={imageSrc} preview={false} draggable={false} />
        </div>
        <div className="p-2">
          <div className="font-semibold text-lg">{newsTitle}</div>
          <div className="text-gray-600">{newsContent}</div>
        </div>
      </div>
    </Link>
  );
};

export default NewsTile;
