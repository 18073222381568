import { NotificationInstance } from "antd/es/notification/interface";
import { NOTIFICATION_CONST } from "../models/constents/core.constants";

export const getColorClass = (hash: string) => {
  switch (hash) {
    case "#9C00EB":
      return "purple";
    case "#F1B100":
      return "mustard";
    case "#177F07":
      return "green";
    case "#32C6EC":
      return "skyLightBlue";
    default:
      return "purple";
  }
};

export const openNotification = (
  api: NotificationInstance,
  msg: string,
  type: string
) => {
  if (type === NOTIFICATION_CONST.SUCCESS) {
    api.success({
      message: msg,
      description: "",
      placement: NOTIFICATION_CONST.PLACEMENT_TOP,
      duration: 5,
      className: `rounded-lg opacity-75 `,
    });
  } else {
    api.error({
      message: msg,
      description: "",
      placement: NOTIFICATION_CONST.PLACEMENT_TOP,
      duration: 5,
      className: `rounded-lg opacity-75`,
    });
  }
};
