import { Form, FormItemProps, Input, InputProps } from "antd";
import * as React from "react";

type DXFormInputProps = {
  itemProps: FormItemProps;
  inputProps: InputProps;
};

const DXFormInput: React.FC<DXFormInputProps> = (props: DXFormInputProps) => {
  const { className, colon = false, ...restItemProps } = props.itemProps;
  return (
    <Form.Item
      className={"ant-form-item-label-show " + className}
      colon={colon}
      {...restItemProps}
    >
      <Input {...props.inputProps} />
    </Form.Item>
  );
};

export default React.memo(DXFormInput);
