import { t } from "i18next";
import * as React from "react";

const LogoSubtitle: React.FC<any> = () => {
  return (
    <>
      <span className="text-red-500">{t("dx.desi")}</span>
      <span className="text-black">{t("dx.x")}</span>
      <span className="text-blue-500">{t("dx.pert")}</span>
    </>
  );
};

export default LogoSubtitle;
