import { NotificationPlacements } from "antd/es/notification/interface";

export const INPUT_ALLOWED_KEYS = [
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Shift",
  "Alt",
  "Control",
  "End",
  "Home",
];
export const NOTIFICATION_CONST = {
  SUCCESS: "success",
  ERROR: "error",
  PLACEMENT_TOP: NotificationPlacements[0],
  PLACEMENT_TOPLEFT: NotificationPlacements[1],
  PLACEMENT_TOPRIGHT: NotificationPlacements[2],
  PLACEMENT_BOTTOM: NotificationPlacements[3],
  PLACEMENT_BOTTOMLEFT: NotificationPlacements[4],
  PLACEMENT_BOTTOMRIGHT: NotificationPlacements[5],
};
export const ROUTES_CONST = {
  ROOT: "/",
  AUTH: "/auth/",
  LOGIN: "login",
  SIGNUP: "register",
  BOOKDEMO: "book-demo",
  HOME: "/home/",
  NEWS: "news",
  ABOUTUS: "aboutus",
  SERVICES: "services",
  CONTACTUS: "contactus",
  FORGET_PASSWORD: "forget-password",
  RESET_PASSWORD: "reset-password",
  DASHBOARD: "dashboard",
  HELPANDSUPPROT: "helpandsupprot",
  ASTRIC_SYMBOL: "*",
};
export const CALCULATION_CONST = {
  KB: 1024,
  MB: 1048576,
};

export const PAGINATION_SETTING = {
  PAGE_SIZE: 10,
};

export const VIEW_KEYS = {
  DARK: "dark",
  LIGHT: "light",
};

export const DATE_TIME_CONSTANTS = {
  DX_LONG_DATE_TIME: "D MM YYYY at h:mm A",
  DX_SHORT_MONTH_YEAR: "MMM YYYY",
  DX_LONG_DO_DATES: "Do of MMMM YYYY",
};
export const DX_BUTTON_TYPE = {
  PRIMARY: "primary",
  EXPIRED: "exoired",
  SECONDARY: "secondary",
  SUBMIT_PRIMARY: "submit_primary",
  UPLOADBUTTON: "uploadButton",
  SUBMIT_UPLOADBUTTON: "submit_uploadButton",
  KEYPAD: "keypad",
  TYPELINK: "TypeLink",
  CLEAR_FORM: "clear_form",
  PRICING_BACK: "pricing_back",
};
export const LOCAL_STORAGE = {
  AUTH: "auth",
};

export enum HTTP_STATUS_CODES {
  UNAUTHORIZED = 401,
}
