import * as React from "react";
import { DX_BUTTON_TYPE } from "../../models/constents/core.constants";
import { Button, ButtonProps } from "antd";

type DXButtonProps = ButtonProps & {
  dxtype?: string;
};

const DXButton: React.FC<DXButtonProps> = (props: DXButtonProps) => {
  const { dxtype, className, ...restInputProps } = props;
  const [scClassName, setScClassName] = React.useState(
    " !capitalize primary-btn custom-sm:h-[40px] font-bold "
  );
  const reSetScClassName = (type: string) => {
    let common = " !capitalize primary-btn custom-sm:h-[40px] font-bold ";
    let submit_common =
      " !capitalize primary-btn custom-sm:h-[40px] font-bold font-secondary ";
    switch (type) {
      case DX_BUTTON_TYPE.PRIMARY:
        setScClassName(className + common);
        break;
      case DX_BUTTON_TYPE.SECONDARY:
        setScClassName(
          className + " !capitalize secondary-btn custom-sm:h-[40px] font-bold "
        );
        break;
      case DX_BUTTON_TYPE.SUBMIT_PRIMARY:
        setScClassName(className + submit_common);
        break;
      case DX_BUTTON_TYPE.PRICING_BACK:
        setScClassName(className + common + " w-[110px] ");
        break;
      case DX_BUTTON_TYPE.UPLOADBUTTON:
        setScClassName(
          className +
            " capitalize bg-white rounded-3xl h-[48px] sm:px-[20px] px-2 "
        );
        break;
      case DX_BUTTON_TYPE.SUBMIT_UPLOADBUTTON:
        setScClassName(
          className +
            " bg-white rounded-3xl h-[48px] px-[20px] font-bold capitalize font-secondary "
        );
        break;
      case DX_BUTTON_TYPE.CLEAR_FORM:
        setScClassName(className + common + " mt-[10px] ");
        break;
      case DX_BUTTON_TYPE.KEYPAD:
        setScClassName("num-btn font-secondary");
        break;
      case DX_BUTTON_TYPE.EXPIRED:
        setScClassName(className + "");
        break;
      case DX_BUTTON_TYPE.TYPELINK:
        setScClassName(
          "!p-0 text-left text-primary 2xl:text-xl lg:text-lg border-none text-base font-secondary tracking-[0.4px] font-medium hover:underline hover:text-primary !shadow-none"
        );
        break;
      default:
        setScClassName(className + common);
        break;
    }
  };
  React.useEffect(() => {
    reSetScClassName(dxtype || "");
    //eslint-disable-next-line
  }, []);
  return <Button className={scClassName} {...restInputProps} />;
};

export default React.memo(DXButton);
