import { createContext, useContext } from "react";
import { ComponentProps } from "../models/interfaces/component";
import { notification } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import axios from "axios";
import { openNotification } from "../helper/dxNotification.helper";
import {
  HTTP_STATUS_CODES,
  NOTIFICATION_CONST,
  ROUTES_CONST,
} from "../models/constents/core.constants";
import { useNavigate } from "react-router-dom";

export interface ApiOperations {
  auth: Function;
  get: Function;
  post: Function;
  put: Function;
  remove: Function;
}
const APIServiceContext = createContext<ApiOperations | null>(null);

export const APIServiceProvider = (props: ComponentProps) => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const authenticationDetail = useSelector((state: RootState) => state.auth);
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const setToken = (config: any) => {
    config.timeout = 10000;
    config.metadata = { startTime: new Date() };
    console.log("authenticationDetail.auth", authenticationDetail.auth);
    if (authenticationDetail.auth.isLoggedin === true) {
      config.headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.auth).token,
      };
    }
    return config;
  };
  axios.interceptors.request.use(setToken);

  axios.interceptors.response.use(
    (response: any) => {
      const elapsedTime: number =
        new Date().getTime() - response.config.metadata.startTime.getTime();
      if (elapsedTime > 10000) {
        const pathSegments = response.config.url.split("/");
        const lastSlug = pathSegments[pathSegments.length - 1];
        console.log(
          "API request took more than 10 seconds.",
          response.config.url
        );
        openNotification(
          api,
          `${lastSlug} API request took more than 10 seconds.`,
          NOTIFICATION_CONST.ERROR
        );
      }

      return response;
    },
    (error) => {
      const status = error.response ? error.response.status : null;
      // TODO handle token expiry
      if (status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        localStorage.clear();
        navigate(ROUTES_CONST.AUTH + ROUTES_CONST.LOGIN);
      } else {
        if (error.response) {
          return Promise.reject(error);
        } else {
          error.response = {
            data: {
              errorMessage: error.message,
              isError: true,
            },
          };
          return Promise.reject(error);
        }
      }
    }
  );

  const auth = (url: string, body: any) => {
    return axios
      .post(url, body)
      .then(function (response) {
        return response
          ? response.data
            ? response.data.result
            : response.data
          : response;
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response.data
            : error.response
          : error;
      });
  };

  const get = (url: string) => {
    return axios
      .get(url)
      .then(function (response: any) {
        return Promise.resolve(response ? response.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response.data
            : error.response
          : error;
      });
  };

  const post = (url: string, body: any) => {
    console.log("post", url, body);
    return axios
      .post(url, body)
      .then(function (response) {
        return Promise.resolve(response ? response.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response.data
            : error.response
          : error;
      });
  };

  const put = (url: string, body: any) => {
    return axios
      .put(url, body)
      .then(function (response) {
        return Promise.resolve(response ? response.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response.data
            : error.response
          : error;
      });
  };

  const remove = (url: string, body?: any) => {
    return axios
      .delete(url, { data: body })
      .then(function (response) {
        return Promise.resolve(response ? response.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response.data
            : error.response
          : error;
      });
  };
  const ApiOperations: ApiOperations = {
    auth,
    get,
    post,
    put,
    remove,
  };
  return (
    <APIServiceContext.Provider value={ApiOperations}>
      {contextHolder}
      {props.children}
    </APIServiceContext.Provider>
  );
};

export const useAPIService = () => {
  return useContext(APIServiceContext);
};
