import * as React from "react";
import SiteLogo from "../../../core/components/SiteLogo";
import { Form, Image, notification } from "antd";
import DXFormInput from "../../../core/components/DXFormInput";
import { openNotification } from "../../../core/helper/dxNotification.helper";
import {
  DX_BUTTON_TYPE,
  NOTIFICATION_CONST,
  ROUTES_CONST,
} from "../../../core/models/constents/core.constants";
import { useDispatch } from "react-redux";
import { login } from "../../../../store/auth.slice";
import { useNavigate } from "react-router-dom";
import {
  AuthServiceOperations,
  useAuthService,
} from "../../services/Auth.service";
import { LOGIN_FORM } from "../../models/constants/auth";
import DXFormPassword from "../../../core/components/DXFormPassword";
import DXFormSubmit from "../../../core/components/DXFormSubmit";
import InterviewImage from "../../../../assets/images/jpeg/interview.jpeg";
import { t } from "i18next";
import { IsValidUserName } from "../../../core/helper/validation.helper";
import LogoSubtitle from "../../../core/components/LogoSubtitle";

const LogInPage: React.FC<any> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const authRepository: AuthServiceOperations | null = useAuthService();

  const handleOnSubmit = async (formData: any) => {
    console.log("handleOnSubmit");
    authRepository?.logIn(formData).then((response: any) => {
      if (response.isError) {
        if (response.errorMessage === "This user is deactivated.!") {
          openNotification(
            api,
            "Your account is deactivated. Follow instruction in your mail for reactivation.!",
            NOTIFICATION_CONST.ERROR
          );
        } else {
          openNotification(
            api,
            response.errorMessage,
            NOTIFICATION_CONST.ERROR
          );
        }
      } else {
        openNotification(api, response.message, NOTIFICATION_CONST.SUCCESS);
        // dispatch(
        //   login({
        //     user_token: response.result.token,
        //     user_token_validTo: response.result.validTo,
        //     refreshToken: response.result.refreshToken,
        //     isLoggedin: true,
        //   })
        // );
        console.log("logged in");

        // if (response.result && response.result.isAlreadyLoggedIn) {
        //   navigate(ROUTES_CONST.HOME + ROUTES_CONST.DASHBOARD);
        // } else {
        //   navigate(ROUTES_CONST.HOME);
        // }
      }
    });
  };
  return (
    <div className="w-full min-h-screen content-center text-center login-page">
      {contextHolder}
      <div className="max-w-dx-lg w-full my-0 mx-auto">
        <div className="flex justify-between">
          <div className="min-h-screen hidden md:inline-block w-[50%] py-0">
            <div className="flex my-4">
              <SiteLogo />
            </div>
            <Image
              className="!h-[76vh]"
              src={InterviewImage}
              preview={false}
              draggable={false}
            />
          </div>
          <div className="md:w-[50%] w-full p-10 bg-white">
            <div className="md:border-4 md:rounded-3xl pb-10 w-full ">
              <div className="text-xl font-semibold m-2 sm:text-2xl sm:font-semibold sm:m-4 md:text-4xl md:font-bold md:m-8">
                {t("auth.login.content.welcome_back_to")}
                <div>
                  <LogoSubtitle />
                </div>
              </div>
              <Form
                form={form}
                name="login"
                size="large"
                layout="vertical"
                autoComplete="off"
                className="custom-form w-ful max-w-[376px] my-2 mx-auto px-2"
                onFinish={handleOnSubmit}
              >
                <div className="mb-10">
                  <DXFormInput
                    inputProps={{
                      className: "font-normal text-sm",
                      size: "large",
                      placeholder: t(
                        "auth.login.form.input.UserName.placeHolder"
                      ),
                      type: "email",
                    }}
                    itemProps={{
                      name: LOGIN_FORM.EMAIL,
                      label: (
                        <span className="font-bold text-lg">
                          {t("auth.login.form.input.UserName.placeHolder")}
                        </span>
                      ),
                      rules: [
                        {
                          required: true,
                          message: t(
                            "auth.login.form.input.UserName.validationErrors.blank"
                          ),
                          validator: (_, value) =>
                            IsValidUserName(value)
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    t(
                                      "auth.login.form.input.UserName.validationErrors.invalid"
                                    )
                                  )
                                ),
                        },
                      ],
                    }}
                  />
                </div>
                <div className="mb-10">
                  <DXFormPassword
                    inputProps={{
                      placeholder: t(
                        "auth.login.form.input.Password.placeHolder"
                      ),
                    }}
                    itemProps={{
                      name: LOGIN_FORM.PASSWORD,

                      label: (
                        <span className="font-bold text-lg">
                          {t("auth.login.form.input.Password.placeHolder")}
                        </span>
                      ),
                      rules: [
                        {
                          required: true,
                          message: t(
                            "auth.login.form.input.Password.validationErrors.blank"
                          ),
                        },
                      ],
                    }}
                  />
                </div>
                <div className="text-center 2xl:mb-7 mb-3.5">
                  <DXFormSubmit
                    itemProps={{}}
                    inputProps={{ className: " w-full uppercase" }}
                    scType={DX_BUTTON_TYPE.SUBMIT_PRIMARY}
                  >
                    {t("auth.login.action.loginBtn")}
                  </DXFormSubmit>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInPage;
