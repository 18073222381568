import { createContext, useContext } from "react";
import { ComponentProps } from "../../core/models/interfaces/component";
import { ApiOperations, useAPIService } from "../../core/services/API.service";
import { logout } from "../../../store/auth.slice";
import { useDispatch } from "react-redux";
import { LOGIN_SLUG } from "../models/constants/auth";

export type LoginPayLoad = {
  Email: string;
  Password: string;
};

const { REACT_APP_API_URL } = process.env;

export interface AuthServiceOperations {
  logIn: Function;
  logout: Function;
  signUp: Function;
  resetPassword: Function;
  forgetPassword: Function;
  bookDemo: Function;
}

export type SignupPayLoad = {
  termsAndConditionIsChecked: string;
  // AuthInitialState: AuthInitialState;
  otp?: string;
};

const AuthServiceContext = createContext<AuthServiceOperations | null>(null);
export const AuthServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();
  const dispatch = useDispatch();

  const logIn = (body: LoginPayLoad) => {
    console.log("APIService login", body);
    return APIService?.post(REACT_APP_API_URL + LOGIN_SLUG.LOGIN, body);
  };

  const logout = () => {
    return APIService?.get(REACT_APP_API_URL + "logout_route_here").then(() => {
      dispatch(logout());
      localStorage.removeItem("persist:desiexpert");
      localStorage.clear();
    });
  };

  const signUp = (body: SignupPayLoad) => {
    return APIService?.post(REACT_APP_API_URL + "signUp_route_here", body);
  };

  const bookDemo = (body: SignupPayLoad) => {
    return APIService?.post(REACT_APP_API_URL + "bookDemo_route_here", body);
  };

  const resetPassword = () => {
    return APIService?.post(REACT_APP_API_URL + "resetPassword_route_here");
  };

  const forgetPassword = (email: string) => {
    return APIService?.post(REACT_APP_API_URL + "forgetPassword_route_here", {
      Email: email,
    });
  };
  const AuthServiceOperations: AuthServiceOperations = {
    logIn,
    forgetPassword,
    logout,
    resetPassword,
    signUp,
    bookDemo,
  };
  return (
    <AuthServiceContext.Provider value={AuthServiceOperations}>
      {props.children}
    </AuthServiceContext.Provider>
  );
};
export const useAuthService = () => {
  return useContext(AuthServiceContext);
};
