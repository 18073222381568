import { t } from "i18next";
import * as React from "react";
const HomeServices = React.lazy(() => import("../../components/HomeServices"));

const Service: React.FC<any> = () => {
  return (
    <div className="">
      <div className="max-w-dx-lg mx-auto">
        <div>
          <HomeServices />
        </div>
      </div>
    </div>
  );
};

export default Service;
