import * as React from "react";
import { Navigate } from "react-router-dom";

const Public: React.FC<any> = ({ children }: any) => {
  let loggedIn = localStorage.getItem("auth");
  if (loggedIn) {
    return <Navigate to="/home/" replace />;
  }
  return children;
};

export default Public;
