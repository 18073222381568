import * as React from "react";
import NewsTile from "../NewsTile";
import Career_Direction from "../../../../assets/images/jpeg/Career_Direction.jpg";
import HR_ROUND from "../../../../assets/images/jpeg/HR_ROUND.jpg";
import interview from "../../../../assets/images/jpeg/interview.jpeg";
import interviews from "../../../../assets/images/jpeg/interview.jpg";
const News: React.FC<any> = () => {
  return (
    <div className="">
      <div className="max-w-dx-lg mx-auto">
        <div className="max-w-dx-lg flex flex-wrap justify-around">
          <NewsTile
            imageSrc={Career_Direction}
            newsContent="Career_Direction"
            newsLink="Career_Direction"
            newsTitle="Career_Direction"
          />
          <NewsTile
            imageSrc={HR_ROUND}
            newsContent="Career_Direction"
            newsLink="Career_Direction"
            newsTitle="Career_Direction"
          />
          <NewsTile
            imageSrc={interview}
            newsContent="Career_Direction"
            newsLink="Career_Direction"
            newsTitle="Career_Direction"
          />
          <NewsTile
            imageSrc={interviews}
            newsContent="Career_Direction"
            newsLink="Career_Direction"
            newsTitle="Career_Direction"
          />
        </div>
      </div>
    </div>
  );
};

export default News;
