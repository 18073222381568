import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import * as React from "react";
import { ComponentProps } from "../../models/interfaces/component";

type LoaderProps = ComponentProps & {
  styleLoadingOutlined?: React.CSSProperties | undefined;
};

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { styleLoadingOutlined } = props;
  return (
    <div className="flex justify-center items-center h-full">
      <Spin indicator={<LoadingOutlined style={styleLoadingOutlined} spin />} />
    </div>
  );
};

export default Loader;
