import { Form, notification } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthServiceOperations,
  useAuthService,
} from "../../../auth/services/Auth.service";
import { openNotification } from "../../../core/helper/dxNotification.helper";
import {
  DX_BUTTON_TYPE,
  NOTIFICATION_CONST,
  ROUTES_CONST,
} from "../../../core/models/constents/core.constants";
import DXFormSubmit from "../../../core/components/DXFormSubmit";
import { t } from "i18next";
import {
  IsValidContactNumber,
  IsValidEmail,
  validateMobileInputOnKeyDown,
} from "../../../core/helper/validation.helper";
import DXFormInput from "../../../core/components/DXFormInput";
import { AimOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { DEMO_FORM } from "../../models/demo";
import DXTextArea from "../../../core/components/DXTextArea";
import LogoSubtitle from "../../../core/components/LogoSubtitle";

const BookDemo: React.FC<any> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const authRepository: AuthServiceOperations | null = useAuthService();

  const handleOnSubmit = async (formData: any) => {
    authRepository?.bookDemo(formData).then((response: any) => {
      if (response.isError) {
        if (response.errorMessage === t("book_demo.this_user_is_deactivated")) {
          openNotification(
            api,
            t("book_demo.your_deactivated_follow_for_reactivation"),
            NOTIFICATION_CONST.ERROR
          );
        } else {
          openNotification(
            api,
            response.errorMessage,
            NOTIFICATION_CONST.ERROR
          );
        }
      } else {
        openNotification(api, response.message, NOTIFICATION_CONST.SUCCESS);

        if (response.result && response.result.isAlreadyLoggedIn) {
          navigate(ROUTES_CONST.HOME + ROUTES_CONST.DASHBOARD);
        } else {
          navigate(ROUTES_CONST.HOME);
        }
      }
    });
  };
  return (
    <div className="w-full min-h-screen content-center">
      {contextHolder}
      <div className="max-w-dx-lg w-full my-0 mx-auto">
        <div className="flex justify-between">
          <div className="min-h-screen hidden md:inline-block w-[50%] py-0 px-[5%]">
            <div className="font-semibold text-3xl pt-16 pb-6">
              {t("book_demo.content.explore_our_demo")}
            </div>
            <div className="flex py-4">
              <span className="p-1 h-10 w-10 content-center inline-block border border-sky-500 rounded-lg bg-sky-100 mx-2">
                <AimOutlined className="px-2 text-sky-600" />
              </span>
              <div>
                <div className="text-2xl">
                  {t("book_demo.content.our_approach")}
                </div>
                <div className="text-sm text-gray-600 p-2">
                  {t(
                    "book_demo.content.discover_how_we_transforming_landscape_technical_recruiting"
                  )}
                </div>
              </div>
            </div>
            <div className="flex py-4">
              <span className="p-1 h-10 w-10 content-center border border-sky-500 rounded-lg bg-sky-100 mx-2">
                <CheckCircleOutlined className="px-2 text-sky-600" />
              </span>
              <div>
                <div className="text-2xl">
                  {t("book_demo.content.how_we_support_goals")}
                </div>
                <div className="text-sm text-gray-600 p-2">
                  {t("book_demo.content.Learn_to_enhance_hiring_process")}
                </div>
              </div>
            </div>
            <div className="flex py-4">
              <span className="p-1 h-10 w-10 content-center border border-sky-500 rounded-lg bg-sky-100 mx-2">
                <CheckCircleOutlined className="px-2 text-sky-600" />
              </span>
              <div>
                <div className="text-2xl">
                  {t("book_demo.content.begin_journey_with")}
                  <div className="inline px-1">
                    <LogoSubtitle />
                  </div>
                </div>
                <div className="text-sm text-gray-600 p-2">
                  {t(
                    "book_demo.content.stay_ahead_start_and_gain_competitive_edge_over_peers"
                  )}
                </div>
              </div>
            </div>
            <div className="mx-8 my-8 w-full border-b-2"></div>
          </div>
          <div className="md:w-[50%] w-full h-full justify-items-center p-10 bg-white">
            <div className="border-2 rounded-3xl pb-10 w-full border-sky-300 px-[10%]">
              <div className="w-full text-center text-xl text-sky-500 font-semibold my-2 sm:text-2xl sm:font-semibold sm:my-4 md:text-4xl md:font-bold md:my-8">
                {t("book_demo.form.action.bookdemo")}
              </div>
              <Form
                form={form}
                name="bookdemo"
                size="large"
                layout="vertical"
                autoComplete="off"
                className="custom-form w-full my-2 mx-auto px-2"
                onFinish={handleOnSubmit}
              >
                <div className="mb-6">
                  <DXFormInput
                    inputProps={{
                      className: "font-normal text-sm",
                      size: "large",
                      placeholder: t(
                        "book_demo.form.input.fullName.placeHolder"
                      ),
                    }}
                    itemProps={{
                      name: DEMO_FORM.FULLNAME,
                      label: "",
                      rules: [
                        {
                          required: true,
                          message: t(
                            "book_demo.form.input.fullName.validationErrors.blank"
                          ),
                        },
                      ],
                    }}
                  />
                </div>
                <div className="mb-6">
                  <DXFormInput
                    inputProps={{
                      className: "font-normal text-sm",
                      size: "large",
                      placeholder: t(
                        "book_demo.form.input.company.placeHolder"
                      ),
                    }}
                    itemProps={{
                      name: DEMO_FORM.COMPANY,
                      label: "",
                    }}
                  />
                </div>
                <div className="mb-6">
                  <DXFormInput
                    inputProps={{
                      className: "font-normal text-sm",
                      size: "large",
                      placeholder: t("book_demo.form.input.role.placeHolder"),
                    }}
                    itemProps={{
                      name: DEMO_FORM.ROLE,
                      label: "",
                    }}
                  />
                </div>
                <div className="mb-10">
                  <DXFormInput
                    inputProps={{
                      className: "font-normal text-sm",
                      size: "large",
                      placeholder: t("book_demo.form.input.email.placeHolder"),
                      type: "email",
                    }}
                    itemProps={{
                      name: DEMO_FORM.EMAIL,
                      label: "",
                      rules: [
                        {
                          required: true,
                          message: t(
                            "book_demo.form.input.email.validationErrors.blank"
                          ),
                          validator: (_, value) =>
                            IsValidEmail(value)
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    t(
                                      "book_demo.form.input.email.validationErrors.invalid"
                                    )
                                  )
                                ),
                        },
                      ],
                    }}
                  />
                </div>
                <div className="mb-10">
                  <DXFormInput
                    inputProps={{
                      className: "font-normal text-sm",
                      onKeyDown: validateMobileInputOnKeyDown,
                      size: "large",
                      placeholder: t(
                        "book_demo.form.input.contact.placeHolder"
                      ),
                      type: "number",
                    }}
                    itemProps={{
                      name: DEMO_FORM.CONTACT_NUM,
                      label: "",
                      rules: [
                        {
                          required: true,
                          message: t(
                            "book_demo.form.input.contact.validationErrors.blank"
                          ),
                          validator: (_, value) =>
                            IsValidContactNumber(value)
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    t(
                                      "book_demo.form.input.contact.validationErrors.invalid"
                                    )
                                  )
                                ),
                        },
                      ],
                    }}
                  />
                </div>
                <div className="mb-10">
                  <DXFormInput
                    inputProps={{
                      className: "font-normal text-sm",
                      size: "large",
                      placeholder: t(
                        "book_demo.form.input.info_source.placeHolder"
                      ),
                    }}
                    itemProps={{
                      name: DEMO_FORM.INFO_SOURCE,
                      label: "",
                    }}
                  />
                </div>
                <div className="mb-10">
                  <DXTextArea
                    inputProps={{
                      className: "font-normal text-sm",
                      size: "large",
                      placeholder: t(
                        "book_demo.form.input.message.placeHolder"
                      ),
                    }}
                    itemProps={{
                      name: DEMO_FORM.MESSAGE,
                      label: "",
                    }}
                  />
                </div>
                <div className="text-center 2xl:mb-7 mb-3.5">
                  <DXFormSubmit
                    itemProps={{}}
                    inputProps={{ className: " w-full uppercase" }}
                    scType={DX_BUTTON_TYPE.SUBMIT_PRIMARY}
                  >
                    {t("book_demo.form.action.bookdemo")}
                  </DXFormSubmit>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
