import * as React from "react";

type ForgetPasswordPageProps = {
  //
};

const ForgetPasswordPage: React.FC<any> = () => {
  return <div>ForgetPasswordPage</div>;
};

export default ForgetPasswordPage;
