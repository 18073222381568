import { I18nextProvider } from "react-i18next";
import "./App.css";
import { Outlet } from "react-router-dom";
import i18n from "./i18next";
import { APIServiceProvider } from "./modules/core/services/API.service";
import { AuthServiceProvider } from "./modules/auth/services/Auth.service";

function App() {
  return (
    <I18nextProvider i18nIsDynamicList={false} i18n={i18n} defaultNS={"locale"}>
      <APIServiceProvider>
        <AuthServiceProvider>
          <Outlet />
        </AuthServiceProvider>
      </APIServiceProvider>
    </I18nextProvider>
  );
}

export default App;
