import { Image } from "antd";
import * as React from "react";
import PageNotFound404 from "../../../../assets/images/png/page_not_found_404.png";
import { Link } from "react-router-dom";
import { ROUTES_CONST } from "../../models/constents/core.constants";

const PageNotFound: React.FC<any> = () => {
  return (
    <div>
      <Link to={ROUTES_CONST.ROOT}>
        <Image
          className="max-h-[70vh]"
          src={PageNotFound404}
          draggable={false}
          preview={false}
        />
      </Link>
    </div>
  );
};

export default PageNotFound;
