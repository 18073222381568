import * as React from "react";
import { Image } from "antd";
import Logo from "../../../../assets/images/png/logo-icon.png";
import { VIEW_KEYS } from "../../models/constents/core.constants";
import { t } from "i18next";
import LogoSubtitle from "../LogoSubtitle";

type SiteLogoProps = {
  bg?: string;
  showSubtitle?: boolean;
  showTitle?: boolean;
};

const SiteLogo: React.FC<SiteLogoProps> = (props: SiteLogoProps) => {
  const { bg = VIEW_KEYS.LIGHT, showSubtitle = true, showTitle = true } = props;
  return (
    <a href="/" className="flex gap-1 font-bold text-gray-700 items-center ">
      <div className="h-10 w-10">
        <Image
          src={Logo}
          className="h-10 w-10 object-contain	text-primary"
          preview={false}
        />
      </div>
      <span>
        <div className={"text-4xl " + (showTitle === true ? "" : " hidden")}>
          <LogoSubtitle />
        </div>
        <div
          className={
            "text-xs font-thin text-nowrap " +
            (bg === VIEW_KEYS.LIGHT ? "" : " text-sky-200 ") +
            (showSubtitle === true ? "" : " hidden")
          }
        >
          {t("dx.logoSubTitle")}
        </div>
      </span>
    </a>
  );
};

export default React.memo(SiteLogo);
