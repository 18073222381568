import * as React from "react";
import {
  GooglePlusOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
  XOutlined,
} from "@ant-design/icons";
import SiteLogo from "../../../core/components/SiteLogo";

const Footer: React.FC<any> = () => {
  return (
    <div className="w-full bg-sky-200 min-h-48 py-12">
      <div className="flex justify-center sm:justify-between w-full max-w-dx-lg my-0 mx-auto ">
        <div className="hidden sm:inline-block">
          <div>
            <SiteLogo />
          </div>
        </div>
        <div className="text-sky-800">
          <div className="flex  ">
            <div className="pt-1 pb-3 px-2 cursor-pointer">News</div>
            <div className="pt-1 pb-3 px-2 cursor-pointer">About US</div>
            <div className="pt-1 pb-3 px-2 cursor-pointer">Services</div>
            <div className="pt-1 pb-3 px-2 cursor-pointer">Contact</div>
          </div>
          <div className="flex w-full justify-evenly text-2xl">
            <TwitterOutlined />
            <WhatsAppOutlined />
            <LinkedinOutlined />
            <GooglePlusOutlined />
            <XOutlined />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
