import { Image } from "antd";
import * as React from "react";
import { Carousel, CarouselProps } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

type DECarouselProps = Partial<CarouselProps> & {
  image_src: string[];
  imageClassName?: string;
};

const createCarouselItemImage = (
  image_src: string,
  index: number,
  imageClassName: string
) => (
  <div key={index}>
    <Image
      className={imageClassName}
      preview={false}
      draggable={false}
      src={image_src}
      alt=""
    />
  </div>
);

const DECarousel: React.FC<DECarouselProps> = (props: DECarouselProps) => {
  const { image_src, imageClassName = "", ...restCarouselProps } = props;
  const baseChildren = (
    <div>
      {image_src.map((image_src, index) =>
        createCarouselItemImage(image_src, index, imageClassName)
      )}
    </div>
  );
  return (
    <Carousel {...restCarouselProps}>{baseChildren.props.children}</Carousel>
  );
};

export default DECarousel;
