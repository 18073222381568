import { ButtonProps, Form, FormItemProps } from "antd";
import * as React from "react";
import DXButton from "../DXButton";

type DXFormSubmitProps = {
  itemProps: FormItemProps;
  inputProps: ButtonProps;
  children?: React.ReactNode;
  scType?: string;
};

const DXFormSubmit: React.FC<DXFormSubmitProps> = (
  props: DXFormSubmitProps
) => {
  const { itemProps, inputProps, scType } = props;
  const { className, ...restInputProps } = inputProps;
  return (
    <Form.Item {...itemProps}>
      <DXButton
        className={className + " primary-btn"}
        dxtype={scType}
        htmlType="submit"
        {...restInputProps}
      >
        {props.children}
      </DXButton>
    </Form.Item>
  );
};

export default React.memo(DXFormSubmit);
