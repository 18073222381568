export const user_name_keys_Regex = /^[a-zA-Z0-9.@!#$%&'*+/=?^_`{|}~-]{1,}$/;
export const user_name_Regex = /^[a-zA-Z0-9.@!#$%&'*+/=?^_`{|}~-]{8,}$/;
export const email_Regex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// export const mobile_Regex = /^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/;
export const simple_mobile_Regex = /^\d{10,10}$/;
export const PASSWORD =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#@%^|$%&`[\]{}*:;+/=?_-])[a-zA-Z0-9!#@%^|$%&`[\]{}*:;+/=?_-]{8,}$/;
export const SMALL_CHARS = /[a-z]/;
export const CAPITAL_CHARS = /[A-Z]/;
export const DIGITS = /\d/;
export const WHITE_SPACE_COUNT_REG = /\S+/g;
export const VALIDID_NUMBER = /^\d{13,13}$/;
export const SPECIAL_CHARS = /[!#@%^|$%&`[\]{}*:;+/=?_-]/;
export const NON_SPECIAL_CHARS = /[.'"]/;
