import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { ROUTES_CONST } from "./modules/core/models/constents/core.constants";
import Public from "./modules/core/view/Public";
// import Protected from "./modules/core/view/Protected";
import Home from "./modules/Home";
import AuthPage from "./modules/auth";
import LogInPage from "./modules/auth/page/LogInPage";
import SignUpPage from "./modules/auth/page/SignUpPage";
import ForgetPasswordPage from "./modules/auth/page/ForgetPasswordPage";
import ResetPasswordPage from "./modules/auth/page/ResetPasswordPage";
import LandingLayout from "./modules/Home/page/LandingLayout";
import AboutUs from "./modules/Home/components/AboutUs";
import Service from "./modules/Home/components/Service";
import News from "./modules/Home/components/News";
import BookDemo from "./modules/Home/components/BookDemo";
import PageNotFound from "./modules/core/components/PageNotFound";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: ROUTES_CONST.ROOT,
        element: <LandingLayout />,
        children: [
          {
            element: <Home />,
            index: true,
          },
          {
            element: <AboutUs />,
            path: ROUTES_CONST.ABOUTUS,
          },
          {
            element: <Service />,
            path: ROUTES_CONST.SERVICES,
          },
          {
            element: <News />,
            path: ROUTES_CONST.NEWS,
          },
          {
            path: ROUTES_CONST.BOOKDEMO,
            element: <BookDemo />,
          },
          {
            path: ROUTES_CONST.ASTRIC_SYMBOL,
            element: <PageNotFound></PageNotFound>,
          },
        ],
      },
      {
        path: ROUTES_CONST.AUTH,
        element: (
          <Public>
            <AuthPage />
          </Public>
        ),
        children: [
          {
            element: <LogInPage />,
            index: true,
          },
          {
            path: ROUTES_CONST.LOGIN,
            element: <LogInPage />,
          },
          {
            path: ROUTES_CONST.SIGNUP,
            element: <SignUpPage />,
          },
          {
            path: ROUTES_CONST.FORGET_PASSWORD,
            element: <ForgetPasswordPage />,
          },
          {
            path: ROUTES_CONST.RESET_PASSWORD,
            element: <ResetPasswordPage />,
          },
        ],
      },
      // {
      //   path: ROUTES_CONST.HOME,
      //   element: (
      //     <Protected>
      //       <Home />
      //     </Protected>
      //   ),
      //   children: [
      //     {
      //       path: ROUTES_CONST.DASHBOARD,
      //       element: <DashbordPage />,
      //     },
      //     {
      //       path: ROUTES_CONST.ABOUTUS,
      //       element: <AboutUs />,
      //     },
      //     {
      //       path: ROUTES_CONST.CONTACTUS,
      //       element: <ContactUs />,
      //     },
      //     {
      //       path: ROUTES_CONST.HELPANDSUPPROT,
      //       element: <HelpAndSupport />,
      //     },
      //     {
      //       path: ROUTES_CONST.MYCOURSES,
      //       element: <MyCoursesPage />,
      //       children: [
      //         {
      //           path: ":id",
      //           element: <MyCoursesDetail />,
      //         },
      //       ],
      //     },
      //     {
      //       path: ROUTES_CONST.MYPROFILE,
      //       element: <MyProfile />,
      //     },
      //     {
      //       path: ROUTES_CONST.MYSETTING,
      //       element: <MySettingsPage />,
      //     },
      //     {
      //       path: ROUTES_CONST.MYSHOPINGCART,
      //       element: <MyShoppingCart />,
      //     },
      //     {
      //       path: ROUTES_CONST.DOWNLOADAPP,
      //       element: <DownloadApp />,
      //     },
      //     {
      //       path: ROUTES_CONST.BILLINGDETAILS,
      //       element: <BillingDetails />,
      //     },
      //     {
      //       path: ROUTES_CONST.SEARCHMORECOURSES,
      //       element: <SearchMoreCourses />,
      //     },
      //   ],
      // },
    ],
  },
]);
export default router;
