import { Form, FormItemProps } from "antd";
import { TextAreaProps } from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";

type DXTextAreaProps = {
  itemProps: FormItemProps;
  inputProps: TextAreaProps;
};

const DXTextArea: React.FC<DXTextAreaProps> = (props: DXTextAreaProps) => {
  const { className, colon, ...restItemProps } = props.itemProps;
  return (
    <Form.Item
      className={"ant-form-item-label-show " + className}
      colon={false}
      {...restItemProps}
    >
      <TextArea {...props.inputProps} />
    </Form.Item>
  );
};

export default DXTextArea;
