import * as React from "react";
import { Outlet } from "react-router-dom";

const AuthPage: React.FC<any> = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AuthPage;
