import * as React from "react";
import { Image } from "antd";
import bannerImage from "../../../../assets/images/jpeg/interview.jpeg";
import consulting_new_job from "../../../../assets/images/jpeg/consulting_new_job.jpeg";
import mock_interview from "../../../../assets/images/jpeg/mock_interview.jpeg";
import salary_negotiations from "../../../../assets/images/jpeg/salary_negotiations.jpeg";
import one_on_one_session from "../../../../assets/images/jpeg/one_on_one_session.jpeg";
import { t } from "i18next";
import DECarousel from "../../../core/components/DECarousel";
// import LogoSubtitle from "../../../core/components/LogoSubtitle";
const HomeServices = React.lazy(() => import("../../components/HomeServices"));
// import { t } from "i18next";

const AboutUs: React.FC<any> = () => {
  return (
    <div className="">
      <Image
        height={"75vh"}
        src={bannerImage}
        preview={false}
        draggable={false}
      />
      <div className="max-w-dx-lg mx-auto">
        <div className="text-center">
          <HomeServices />
        </div>
      </div>
      <div className="max-w-dx-lg mx-auto py-25">
        <div className="text-5xl text-center">
          <div className="font-semibold text-center pb-15">
            {t("about_us.our_interviewer_demography")}
          </div>
          <div>
            <DECarousel
              autoPlay={true}
              infiniteLoop={true}
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              className="dots-bottom-10"
              imageClassName={"rounded-3xl"}
              image_src={[
                consulting_new_job,
                mock_interview,
                salary_negotiations,
                one_on_one_session,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
